// Transforms the oauth/v2/application-authorizations response into the shape of the v1 response
const transformAuthorizeResponseToLegacy = ({
  scopeGroups,
  appId,
  clientId,
  name,
  iconUrl,
  previouslyAuthorizedByUser,
  listingInfo,
  providerInfo
}) => ({
  scopeGroups,
  appId,
  clientId,
  name,
  shortDescription: '',
  description: '',
  // in v1, listingIconUrl is the app icon, in v2: iconUrl is the app icon
  listingIconUrl: iconUrl || undefined,
  authorized: previouslyAuthorizedByUser,
  provider: Object.assign({}, providerInfo ? {
    domain: providerInfo.domain || undefined,
    verified: providerInfo.isVerified
  } : {
    domain: undefined,
    verified: false
  }, listingInfo ? {
    published: listingInfo.isPublished,
    certified: listingInfo.isCertified
  } : {
    published: false,
    certified: false
  })
});
export default transformAuthorizeResponseToLegacy;