import http from 'hub-http/clients/apiClient';
import noPortalHttp from 'hub-http/clients/noPortalApiClient';
import { EarlyRequestKeys } from '../../earlyRequests/earlyRequestKeys';
import { getEarlyRequestOrFetch } from '../../earlyRequests/getEarlyRequestOrFetch';
export const CONNECTION_API_BASE_V3 = '/apps-hublets/public/v3/connection';
export const getAppInfoByClientId = clientId => {
  return getEarlyRequestOrFetch(EarlyRequestKeys.APP_INFO, () => noPortalHttp.get(`${CONNECTION_API_BASE_V3}/info/client-id/${clientId}`));
};
export const fetchInternallyDevelopedApps = () => {
  return getEarlyRequestOrFetch(EarlyRequestKeys.INTERNALLY_DEVELOPED_APPS, () => http.get(`${CONNECTION_API_BASE_V3}/internally-developed`));
};