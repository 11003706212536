import Raven from 'raven-js';
import { registerQuery, useQuery } from 'data-fetching-client';
import { createRpcClientV2 } from 'rpc-client-utils';
import httpNoPortal from 'hub-http/clients/noPortalApiClient';
import { getAllowedAppPortalInstallsByClientIdRpc } from 'apps-service-types/ApplicationsHubletsService/operations';
import { queryResultToRequestStatus } from '../../utils/dataFetchingClientUtils';
const rpcNoPortalClient = createRpcClientV2({
  hubHttpClient: httpNoPortal
});
const query = registerQuery({
  fieldName: 'allowlistedPortals',
  args: ['clientId'],
  fetcher: ({
    clientId
  }) => rpcNoPortalClient.call(getAllowedAppPortalInstallsByClientIdRpc, {
    pathParameters: {
      clientId
    }
  }).catch(error => {
    if (error.cause.status === 404) {
      return null;
    }
    Raven.captureException(error, {
      extra: {
        message: 'There was a problem fetching the allowlisted portals',
        clientId
      }
    });
    throw error;
  })
});
export const useFetchAllowlistedPortals = clientId => {
  const {
    data,
    loading,
    error
  } = useQuery(query, {
    variables: {
      clientId
    }
  });
  const allowlistedPortals = data === null || data === void 0 ? void 0 : data.allowlistedPortals;
  return {
    queryResult: queryResultToRequestStatus({
      hasData: !!allowlistedPortals || allowlistedPortals === null,
      loading,
      error
    }),
    data: allowlistedPortals,
    loading,
    error
  };
};