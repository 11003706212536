import RequestStatus from '../data/models/RequestStatus';
export const queryResultToRequestStatus = ({
  loading,
  error,
  hasData
}) => {
  if (error) {
    return RequestStatus.failed;
  } else if (loading) {
    return RequestStatus.pending;
  } else if (hasData) {
    return RequestStatus.succeeded;
  }
  return RequestStatus.uninitialized;
};