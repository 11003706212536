import Raven from 'raven-js';
import { registerQuery, useQuery } from 'data-fetching-client';
import getAppObjectsFromNames from '../../data/api/CrmObjectsClient';
const query = registerQuery({
  fieldName: 'appObjectNames',
  args: ['names'],
  fetcher: async ({
    names
  }) => {
    try {
      const data = await getAppObjectsFromNames(names);
      return Object.fromEntries(names.map(name => {
        const appObject = data.find(ao => ao.fullyQualifiedName === name);
        return [name, (appObject === null || appObject === void 0 ? void 0 : appObject.singularForm) || (appObject === null || appObject === void 0 ? void 0 : appObject.pluralForm) || (appObject === null || appObject === void 0 ? void 0 : appObject.name)];
      }));
    } catch (error) {
      Raven.captureException(error, {
        extra: {
          query: 'appObjectNames'
        }
      });
      throw error;
    }
  }
});
export const useFetchAppObjectNames = ({
  names
}) => {
  const hasAppObjectScopes = names && names.length > 0;
  const {
    data,
    loading,
    error
  } = useQuery(query, Object.assign({}, hasAppObjectScopes ? {
    variables: {
      names
    }
  } : {
    skip: true
  }));
  return {
    data: data === null || data === void 0 ? void 0 : data.appObjectNames,
    loading,
    error
  };
};