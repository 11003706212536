import I18n from 'I18n';
export const getIsAppObjectScopeGroup = scopeName => {
  return scopeName.startsWith('crm.app.objects.') || scopeName.startsWith('crm.app.schemas.');
};
export const extractFQNFromScopeName = scopeName => {
  if (!getIsAppObjectScopeGroup(scopeName)) {
    return '';
  }
  const parts = scopeName.split('.');
  const FQN = parts[3]; // Get the part after "crm.app.objects" or "crm.app.schemas"

  // If it's not in the expected format with an underscore, return empty string
  if (!FQN || !FQN.includes('_')) {
    return '';
  }

  // Split into ID and name parts
  const [id, ...nameParts] = FQN.split('_');
  // Join name parts and convert to uppercase
  const name = nameParts.join('_').toUpperCase();
  return `${id}_${name}`;
};
export const extractActionFromScopeName = scopeName => {
  if (!getIsAppObjectScopeGroup(scopeName)) {
    return '';
  }
  const parts = scopeName.split('.');
  // For schema properties.write, we want to return "properties.write"
  // For regular actions like read/write/create, we want just that part
  return parts.slice(4).join('.');
};
const extractRawAppObjectNameFromScopeName = scopeName => {
  const FQN = extractFQNFromScopeName(scopeName);

  // Extract everything after the underscore
  const underscoreIndex = FQN.indexOf('_');
  if (underscoreIndex === -1) {
    return '';
  }
  return FQN.slice(underscoreIndex + 1);
};
export const getAppObjectScopeGroupDescription = (scope, appFQNToNameMap) => {
  var _appFQNToNameMap$extr;
  const appObjectScopeGroupType = extractActionFromScopeName(scope.name);
  const extractedAppObjectName = (_appFQNToNameMap$extr = appFQNToNameMap === null || appFQNToNameMap === void 0 ? void 0 : appFQNToNameMap[extractFQNFromScopeName(scope.name)]) !== null && _appFQNToNameMap$extr !== void 0 ? _appFQNToNameMap$extr : extractRawAppObjectNameFromScopeName(scope.name);
  const unknownValue = extractedAppObjectName ? I18n.text('developer-experience-shared-components.scopes.appObjectScopeGroups.unknown', {
    objectName: extractedAppObjectName
  }) : scope.longDescription || scope.shortDescription || scope.name;
  return I18n.text(`developer-experience-shared-components.scopes.appObjectScopeGroups.${appObjectScopeGroupType}`, {
    objectName: extractedAppObjectName,
    defaultValue: unknownValue
  });
};