import Raven from 'raven-js';
import quickFetch from 'quick-fetch';
export const getEarlyRequestOrFetch = (earlyRequestName, fetch) => {
  const earlyMetaRequest = quickFetch.getRequestStateByName(earlyRequestName);
  let resultPromise;
  if (earlyMetaRequest) {
    resultPromise = new Promise((resolve, reject) => {
      earlyMetaRequest.whenFinished(result => {
        resolve(result);
        quickFetch.removeEarlyRequest(earlyRequestName);
      });
      earlyMetaRequest.onError(() => {
        fetch().then(resolve, reject).catch(error => Raven.captureException(error, {
          extra: {
            earlyRequestName
          }
        }));
        quickFetch.removeEarlyRequest(earlyRequestName);
      });
    });
  } else {
    resultPromise = fetch();
  }
  return resultPromise;
};