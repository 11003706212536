import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import noPortalApiClient from 'hub-http/clients/noPortalApiClient';
import transformAuthorizeResponseToLegacy from '../../utils/authorizeResponseTransformer';
import { EarlyRequestKeys } from '../../earlyRequests/earlyRequestKeys';
import { getEarlyRequestOrFetch } from '../../earlyRequests/getEarlyRequestOrFetch';

/**
 * We use this request when we are connected to a portal but also when we have no
 * connected portal and a portal is selected from an account picker to be
 * authed. In that case, we want to use the `noPortalApiClient`. If the `hubId`
 * fetched using `PortalIdParser` is undefined, we can presume that we're in
 * the no-portal case.
 */

export function fetch(data, {
  hubId = PortalIdParser.get()
} = {}) {
  const baseUrl = getFullUrl('app-api', {
    hubletOverride: data.hublet
  });
  const authUrl = `${baseUrl}/oauth/v2/application-authorizations`;
  if (hubId) {
    return getEarlyRequestOrFetch(EarlyRequestKeys.APP_AUTHORIZATIONS, () => http.post(authUrl, {
      data
    })).then(transformAuthorizeResponseToLegacy);
  }
  return noPortalApiClient.post(`${authUrl}?portalId=${data.hubId}`, {
    data
  }).then(transformAuthorizeResponseToLegacy);
}
export const fetchInstalledScopes = clientId => {
  const portalId = PortalIdParser.get();
  return getEarlyRequestOrFetch(EarlyRequestKeys.SCOPE_GROUPS, () => http.get(`oauth/v2/scope-groups/client-hub/${clientId}`, {
    portalId
  }));
};