import styled from 'styled-components';
import Heading from '../Heading';
const H2 = styled(Heading).withConfig({
  displayName: "H2",
  componentId: "qz8gfl-0"
})([""]);
H2.propTypes = Heading.propTypes;

/* eslint-disable-next-line hubspot-dev/no-default-props-assignment */
H2.defaultProps = Object.assign({}, Heading.defaultProps, {
  tagName: 'h2'
});
H2.displayName = 'H2';
export default H2;